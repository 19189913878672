import React from "react";
import "./App.css";
import datImage from "./assets/dat.PNG";
import distributedImage from "./assets/distributed-network.png";

function App() {
  return (
    <>
      <nav>
        <a href="#card-one">1: The web today</a>
        <a href="#card-two">2: Beaker Browser</a>
        <a href="#card-three">3: Walkthrough</a>
        <a href="#card-four">4: Dat Protocol</a>
        <a href="#card-five">5: Final Thoughts</a>
        <a href="#card-six">6: Useful Links</a>
      </nav>
      <div class="hero">
        <h1>Beaker Browser: peer-to-peer web with the dat:// protocol</h1>
      </div>

      <main>
        <div class="main-card">
          <div id="card-one" class="outer-card">
            <div class="card">
              <h2>The Web Today</h2>
              <p>Mostly based on the client/server model</p>
              <p>Cloud services by big corporations (e.g google, amazon ...)</p>
              <p>Privacy: User does not have full control of their data</p>
              <p>How do you share files between two computers?</p>
            </div>
          </div>
          <div id="card-two" class="outer-card">
            <div class="card">
              <h2>Beaker Browser</h2>
              <p>Experimental peer-to-peer browser</p>
              <p>
                Supports the dat:// protocol as well as http:// and https://
              </p>
              <p>
                Github{" "}
                <a
                  target="_blank"
                  href="https://github.com/beakerbrowser/beaker"
                >
                  repo
                </a>{" "}
                for the project
              </p>
              <p>
                Created by{" "}
                <a target="_blank" href="dat://bluelinklabs.com/">
                  Blue link labs
                </a>
              </p>
            </div>
          </div>
          <div id="card-three" class="outer-card">
            <div class="card">
              <h2>Walkthrough</h2>
              <p>Can inspect all files served using the dat protocol</p>
              <p>Github like interface with live editor</p>
              <p>Can also create a page with one click</p>
              <p>Has support for version control, similar to git</p>
              <p>Opt-in to seed pages</p>
              <p>Live reloading 👌</p>
            </div>
          </div>
          <div id="card-four" class="outer-card">
            <div class="card">
              <h2>The dat protocol: A distributed network</h2>
              <p>
                Dat is a protocol for sharing files over a distributed network
              </p>
              <p>Users make up the network, acting as both client and server</p>
              <p>Positive: scales, reduces bandwidth, more privacy</p>
              <img src={datImage} />
              <img src={distributedImage} />
            </div>
          </div>
          <div id="card-five" class="outer-card">
            <div class="card">
              <h2>Final thoughts</h2>
              <p>Still an experimental browser with limited features</p>
              <p>New version of beaker is on the way🔥</p>
              <p>Dat CLI and GUI might be something to check out</p>
              <p></p>
            </div>
          </div>
          <div id="card-six" class="outer-card">
            <div class="card">
              <h2>Useful Links</h2>
              <p>
                <a target="_blank" href="dat://beakerbrowser.com/docs/">
                  Beaker browser docs
                </a>
              </p>
              <p>
                <a
                  target="_blank"
                  href="https://docs.datproject.org/docs/intro"
                >
                  Dat docs
                </a>
              </p>
              <p>
                <a
                  target="_blank"
                  href="https://github.com/beakerbrowser/beaker"
                >
                  Github repo
                </a>
              </p>
              <p>
                <a target="_blank" href="https://hashbase.io/">
                  Hosting peer-to-peer web
                </a>
              </p>
              <p>
                <a
                  target="_blank"
                  href="https://www.youtube.com/watch?v=4DFalocsoQo"
                >
                  Talk about Beaker Browser by the author
                </a>
              </p>
              <p>
                <a target="_blank" href="https://twitter.com/BeakerBrowser">
                  Beaker Browser twitter
                </a>
              </p>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default App;
